@tailwind base;
@tailwind components;
@tailwind utilities;


html, body {
  height: 100%; overflow:hidden
}

body {
  color: #152852;
  height: 100vh; 
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: hidden;
  margin: 0;
}

@media only screen and (max-width: 1023px) {
  body {
    height: 100vh;
    max-height:100%;
    overflow-y: hidden;
  }
}


.spacer { 
  aspect-ratio: 960/540;
  width: 100%;
  height: 100vh;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.bg-image {
  background-image: url('./layer.svg')
}
